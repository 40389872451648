import PropTypes from 'prop-types';
import React from 'react';
import {
  resendSAPSubmission,
  getSAPSubmission,
  getSAPBasicSubmission,
  submitToSAP,
} from '../../../../api/Integrations/SAPAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'reactstrap';
import validation, { mapErrors } from '../../../../js/utils/validation';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { useFormKeypress, useMounted } from '../../../Base/hooks';
import IntegrationInput from './IntegrationInput';
import { Loader } from '../../../Base/Loading';
import { WorkflowContext } from '../../../Base/contexts/WorflowContext';
import Tippy from '@tippyjs/react';

function isCreateRestricted(res, candidateProfileSummary, componentName) {
  const buttonRestrictions = res?.find(({ component }) => component === componentName) || {};

  if (!buttonRestrictions?.restrictions?.length) return false;

  const isRestricted = buttonRestrictions.restrictions.some(({ component, enableState }) => {
    if (component === 'RTW') {
      const statesToCheck = ['REJECTED', 'CONTINUOUS', 'TIME_LIMITED'];
      const shouldCheckOutcomeStatus = statesToCheck.some((state) => enableState.includes(state));

      if (shouldCheckOutcomeStatus) {
        return candidateProfileSummary.rightToWork.data.outcomeStatus
          ? !enableState.some((state) => state === candidateProfileSummary.rightToWork.data.outcomeStatus)
          : true;
      }

      return candidateProfileSummary.rightToWork.status
        ? !enableState.some((state) => state === candidateProfileSummary.rightToWork.status)
        : true;
    }

    if (component === 'ONBOARDING') {
      return candidateProfileSummary.onboarding.status
        ? !enableState.some((state) => state === candidateProfileSummary.onboarding.status)
        : true;
    }

    if (component === 'EDOC') {
      return candidateProfileSummary.edoc.length > 0
        ? candidateProfileSummary.edoc.every((edoc) => {
            return enableState.some((state) => state !== edoc.status);
          })
        : false;
    }
  });

  return isRestricted ? buttonRestrictions.restrictionMsg : false;
}

const sapInputConfig = [
  {
    id: 'residencyStatus',
    label: 'Residency Status',
    type: 'select',
    options: [
      { value: 'GB01', label: 'UK Nationals' },
      { value: 'GB03', label: 'Non UK/CTA Nationals' },
      { value: 'GBCT', label: 'UK Common Travel Area' },
    ],
  },
  {
    id: 'proofOfIdentity',
    label: 'Proof Of Identity',
    type: 'select',
    options: [
      { value: 'A01', label: 'Passport (UK)', residencyStatus: 'GB01' },
      { value: 'A08', label: 'Birth/Adoption Certificate (UK)', residencyStatus: 'GB01' },
      { value: 'A12', label: 'Full Registration/Naturalisation Certificate (UK)', residencyStatus: 'GB01' },
      { value: 'A05', label: 'Online Home Office Check - Indefinite Leave to Remain', residencyStatus: 'GB03' },
      { value: 'A06', label: 'Passport with Indefinite leave to remain', residencyStatus: 'GB03' },
      { value: 'A07', label: 'Immigration Status Document - Indefinite Leave to Remain', residencyStatus: 'GB03' },
      { value: 'B01', label: 'Online Home Office Check - Limited Leave', residencyStatus: 'GB03' },
      { value: 'B02', label: 'Passport with Limited Leave to Remain', residencyStatus: 'GB03' },
      { value: 'B07', label: 'Immigration Status Document - Limited Leave to Remain', residencyStatus: 'GB03' },
      { value: 'B18', label: 'Frontier Worker Permit (Any)', residencyStatus: 'GB03' },
      { value: 'B22', label: 'Application Registration Card + Positive Verification Notice', residencyStatus: 'GB03' },
      { value: 'B23', label: 'Positive Verification Notice', residencyStatus: 'GB03' },
      { value: 'Z01', label: 'British National Overseas (Hong Kong)', residencyStatus: 'GB03' },
      { value: 'Z02', label: 'Online Home Office Check - EU Settled Status (PERM)', residencyStatus: 'GB03' },
      { value: 'Z03', label: 'Online Home Office Check - EU Pre Settled Status (5 YEARS)', residencyStatus: 'GB03' },
      { value: 'A01', label: 'Passport (UK)', residencyStatus: 'GBCT' },
      { value: 'A09', label: 'Birth/Adoption Certificate (Ch. Islands/Isle of Man/Ireland)', residencyStatus: 'GBCT' },
      { value: 'A16', label: 'Passport/Passport Card (Ireland)', residencyStatus: 'GBCT' },
      { value: 'A18', label: 'Channel Islands/Isle of Man issued doc with Indefinite Leave', residencyStatus: 'GBCT' },
      { value: 'B16', label: 'Channel Islands/Isle of Man issued doc with Limited Leave', residencyStatus: 'GBCT' },
      { value: 'B18', label: 'Frontier Worker Permit (Any)', residencyStatus: 'GBCT' },
    ],
  },
  {
    id: 'countryOfIssue',
    label: 'Country Of Issue',
    type: 'select',
    url: 'integration/data/sap/nationalities',
  },
  {
    id: 'visaType',
    label: 'Visa Type',
    type: 'select',
    options: [
      { label: 'Limited leave to remain - Ancestry', value: '0005', proofOfIdentity: ['B01', 'B02'] },
      { label: 'EEA Family Member - Indefinite', value: '0006', proofOfIdentity: ['B01', 'B02'] },
      { label: 'EEA Family Member - Limited', value: '0007', proofOfIdentity: ['B01', 'B02'] },
      {
        label: 'Limited leave to remain',
        value: '0009',
        proofOfIdentity: ['B01', 'B02', 'B07', 'B18', 'Z01', 'Z03'],
      },
      { label: 'Student Visa - 20 hours in term time', value: '0014', proofOfIdentity: ['B01', 'B02'] },
      { label: 'Student Visa - 10 hours in term time', value: '0016', proofOfIdentity: ['B01', 'B02'] },
      { label: 'Home Office Application', value: '0101', proofOfIdentity: ['B22', 'B23'] },
      { label: 'Limited leave to remain - Graduate', value: '9001', proofOfIdentity: ['B01', 'B02'] },
      {
        label: 'Limited leave to remain Tier 5 Temp Wrkr',
        value: '9002',
        proofOfIdentity: ['B01', 'B02'],
      },
      { label: 'Student Visa', value: '9004', proofOfIdentity: ['B01', 'B02'] },
    ],
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
  },
  {
    id: 'expiryDate',
    label: 'Expiry Date',
    type: 'date',
  },
  {
    id: 'issueDate',
    label: 'Issue Date',
    type: 'date',
  },
  {
    id: 'entryDate',
    label: 'Entry Date',
    type: 'date',
  },
  {
    id: 'sponsorshipNumber',
    label: 'Sponsorship Number',
    type: 'text',
  },
  {
    id: 'endDate',
    label: 'End Date',
    type: 'date',
  },
  {
    id: 'sterlingNumber',
    label: 'Sterling Number',
    type: 'text',
  },
];

function valueSetter(url, value, isDisabled, type, formData, id, options) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  let updatedOptions = options;

  if (options && options[0]?.residencyStatus) {
    updatedOptions = options.reduce((acc, option) => {
      if (option.residencyStatus && formData.residencyStatus && option.residencyStatus === formData.residencyStatus) {
        acc.push(option);
      }
      return acc;
    }, []);
  }

  if (options && options[0]?.proofOfIdentity) {
    updatedOptions = options.reduce((acc, option) => {
      if (
        option.proofOfIdentity &&
        formData.proofOfIdentity &&
        option.proofOfIdentity?.includes(formData.proofOfIdentity)
      ) {
        acc.push(option);
      }
      return acc;
    }, []);
  }

  return { url, value: convertedValue, isDisabled, options: updatedOptions };
}

const validationConfig = () => {
  return sapInputConfig.reduce((acc, { id, type, required, inputProps }) => {
    const { min, max } = inputProps || {};
    return [...acc, { id, required, type, ...(type === 'number' ? { type, min, max } : {}) }];
  }, []);
};

function SAPForm({ applicantId, candidateProfileSummary }) {
  const { data } = useContext(WorkflowContext);
  const initFormData = sapInputConfig.reduce(
    (acc, { id, type, defaultValue }) => ({ ...acc, [id]: type === 'number' ? defaultValue : '' }),
    {},
  );

  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [formData, setFormData] = useState(initFormData);
  const [allData, setAllData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState('UNKNOWN');
  const [failedMessage, setFailedMessage] = useState('');
  const [additionalSubmissionStatus, setAdditionalSubmissionStatus] = useState('UNKNOWN');
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckLoading, setIsCheckLoading] = useState(true);
  const [formKey, setFormKey] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    async function checkBasicData() {
      try {
        const resp = await retryableAPICall(() => getSAPBasicSubmission(applicantId));

        if (resp.status === 'SUCCESS') {
          setSubmittedStatus('SUCCESS');
          setFailedMessage('');
        } else if (resp.status === 'FAILED') {
          setFailedMessage(resp.message);
          setSubmittedStatus('FAILED');
          setIsLoading(true);
        } else {
          setSubmittedStatus('UNKNOWN');
          setFailedMessage('');
        }
      } catch (error) {
        setSubmittedStatus('UNKNOWN');
        console.error('Error during form submission:', error);
      } finally {
        setIsCheckLoading(false);
      }
    }

    if (isMounted()) {
      checkBasicData();
    }
  }, [isMounted, applicantId]);

  useEffect(() => {
    async function checkData() {
      try {
        const resp = await retryableAPICall(() => getSAPSubmission(applicantId));

        if (typeof resp === 'string') {
          setAdditionalSubmissionStatus('ERROR');
        } else {
          setAdditionalSubmissionStatus('FAILED');

          setAllData(resp?.sentSubmission);
          setFormData({
            residencyStatus: resp?.sentSubmission?.residencyStatus,
            proofOfIdentity: resp?.sentSubmission?.proofOfIdentity,
            documentNumber: resp?.sentSubmission?.documentNumber,
            countryOfIssue: resp?.sentSubmission?.countryOfIssue,
            visaType: resp?.sentSubmission?.visaType,
            startDate: resp?.sentSubmission?.startDate,
            expiryDate: resp?.sentSubmission?.expiryDate,
            issueDate: resp?.sentSubmission?.issueDate,
            entryDate: resp?.sentSubmission?.entryDate,
            sponsorshipNumber: resp?.sentSubmission?.sponsorshipNumber,
            endDate: resp?.sentSubmission?.endDate,
            sterlingNumber: resp?.sentSubmission?.sterlingNumber,
          });
        }
      } catch (error) {
        setAdditionalSubmissionStatus('ERROR');
        console.error('Error during form submission:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (isMounted() && submittedStatus === 'FAILED' && isLoading) {
      checkData();
    }
  }, [isMounted, applicantId, submittedStatus, isLoading]);

  function handleChange(id, value) {
    const updatedData = { ...formData, [id]: value };

    if (id === 'residencyStatus') {
      updatedData.proofOfIdentity = undefined;
      updatedData.visaType = undefined;
    }

    if (id === 'proofOfIdentity') {
      updatedData.visaType = undefined;
    }

    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    const errObj = validation(validationConfig(formData), formData);
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      try {
        const resp = await retryableAPICall(() =>
          resendSAPSubmission(applicantId, {
            ...allData,
            ...formData,
          }),
        );
        if (typeof resp === 'string') {
          toast.error('Error submitting form, please try again or contact support');
        } else {
          setFormData(resp);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
        toast.error('Error submitting form, please try again or contact support');
      }
    }

    setIsSaving(false);
  }

  async function sendToSAP() {
    setIsSaving(true);

    const result = await retryableAPICall(() => submitToSAP(applicantId));

    if (typeof result === 'string') {
      toast.error('Could not submit to SAP');
    } else {
      setSubmittedStatus('PENDING');
      toast.success('Candidate successfully submitted to SAP');
    }

    setIsSaving(false);
  }

  function reset() {
    setFormData({ ...initFormData });
    setErrors({});
    setFormKey(Math.random().toString(10));
  }

  const isCreateButtonRestricted =
    isSaving || isLoading || isCheckLoading || isCreateRestricted(data, candidateProfileSummary, 'sap_submit_button');

  if (isLoading || isCheckLoading) {
    return (
      <>
        <hr />
        <Row>
          <Loader />
        </Row>
      </>
    );
  }

  if (submittedStatus === 'UNKNOWN') {
    return (
      <>
        <hr />
        <Row>
          <Col>
            <Tippy content={isCreateButtonRestricted} disabled={!isCreateButtonRestricted}>
              <div className="float-end">
                <CreateButton action={sendToSAP} disabled={isCreateButtonRestricted || isSaving} label="Send to SAP" />
              </div>
            </Tippy>
          </Col>
        </Row>
      </>
    );
  }

  if (submittedStatus === 'PENDING') {
    return (
      <>
        <hr />
        <Row>
          <Col>
            <div className="d-flex align-items-center mb-2">
              <h4 className="mb-0 me-auto">SAP</h4>
            </div>
            <div className="text-center">
              <h6>Submission pending</h6>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  if (submittedStatus === 'SUCCESS') {
    return (
      <>
        <hr />
        <Row>
          <Col>
            <div className="d-flex align-items-center mb-2">
              <h4 className="mb-0 me-auto">SAP</h4>
            </div>
            <div className="text-center">
              <h6>Successfully submitted to SAP</h6>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  if (additionalSubmissionStatus === 'ERROR') {
    return (
      <>
        <hr />
        <Row>
          <Col>
            <div className="d-flex align-items-center mb-2">
              <h4 className="mb-0 me-auto">SAP</h4>
            </div>
            <div className="text-center">
              <h6>Error loading SAP additional data</h6>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <hr />
      <Row>
        <Col>
          <div className="d-flex align-items-center mb-2">
            <h4 className="mb-0 me-auto">SAP</h4>
          </div>
          <Row>
            <Col>
              <div className="alert alert-danger">
                <p>{failedMessage}</p>
              </div>
            </Col>
          </Row>
          <Form key={formKey} innerRef={formRef}>
            {sapInputConfig.map((input) => {
              const { id, type, label, options, required, inputProps, dataMapper, url: initialUrl } = input;
              const updatedValues = valueSetter(initialUrl, formData[id] || '', false, type, formData, id, options);

              if (updatedValues.isHidden || (updatedValues.options && updatedValues.options.length === 0)) {
                return null;
              }

              return (
                <IntegrationInput
                  key={id}
                  dataMapper={dataMapper}
                  error={errors[id]}
                  id={id}
                  inputProps={inputProps}
                  isClearable={type === 'select' && options?.length > 1}
                  isFile={id === 'visaFileId'}
                  isDisabled={updatedValues?.isDisabled}
                  label={label}
                  onChange={(val) => {
                    handleChange(id, val);
                  }}
                  options={updatedValues.options}
                  readOnly={false}
                  required={required}
                  type={type}
                  url={updatedValues?.url}
                  value={updatedValues?.value}
                />
              );
            })}
            <div className="float-end d-flex">
              <CancelButton
                className="mt-2"
                label="Clear"
                isLoading={isSaving}
                disabled={isSaving}
                action={() => reset()}
              />
              <Tippy content={isCreateButtonRestricted} theme="ats" disabled={!isCreateButtonRestricted}>
                <CreateButton
                  className="mt-2 me-2"
                  action={sendToSAP}
                  disabled={isCreateButtonRestricted || isSaving}
                  label="Send to SAP"
                />
              </Tippy>
              <Tippy
                content={isCreateButtonRestricted ? isCreateButtonRestricted || 'Cannot submit at this time' : null}
                theme="ats"
                disabled={!isCreateButtonRestricted}
              >
                <div>
                  <CreateButton
                    className="mt-2"
                    label={isSaving ? 'Submitting data...' : `Resubmit Right to Work to SAP`}
                    isLoading={isSaving || isLoading}
                    disabled={isCreateButtonRestricted}
                    action={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}
                  />
                </div>
              </Tippy>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
}

SAPForm.propTypes = {
  applicantId: PropTypes.string,
};

SAPForm.defaultProps = {
  applicantId: null,
};

export default SAPForm;
